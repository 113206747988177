function loadSliders() {
	$('.gallery__inner').slick({
		centerMode: true,
		centerPadding: '0',
		slidesToShow: 3,
		prevArrow: "<button class='gallery__button gallery__button--prev' aria-label='Previous' type='button'><img src='https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1722863879/Sites/bounce-city-321/arrow-gallery.png' alt=''/></button>",
		nextArrow: "<button class='gallery__button gallery__button--next' aria-label='Next' type='button'><img src='https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1722863879/Sites/bounce-city-321/arrow-gallery.png' alt=''/></button>",
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
	$('.homepage-listings').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 4,
		prevArrow: "<button class='homepage-listings__button homepage-listings__button--prev' aria-label='Previous' type='button'><img src='https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1722863879/Sites/bounce-city-321/arrow-gallery.png' alt=''/></button>",
		nextArrow: "<button class='homepage-listings__button homepage-listings__button--next' aria-label='Next' type='button'><img src='https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1722863879/Sites/bounce-city-321/arrow-gallery.png' alt=''/></button>",
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
	$('.featured-rotator__inner').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 7,
		prevArrow: "<button class='featured-rotator__button featured-rotator__button--prev' aria-label='Previous' type='button'><img src='https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1722863879/Sites/bounce-city-321/arrow-rotator.png' alt=''/></button>",
		nextArrow: "<button class='featured-rotator__button featured-rotator__button--next' aria-label='Next' type='button'><img src='https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1722863879/Sites/bounce-city-321/arrow-rotator.png' alt=''/></button>",
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			}
		]
	});
}
var sliderScript = document.getElementById("deferredSlickScript");
sliderScript.addEventListener('load', function () {
	loadSliders();
});
$(document).ready(function () {
	$('.textContent a').addClass("has-tooltip");
	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});
	$("a.has-tooltip").tooltip({
		placement: "auto"
	});
	$('.toplinks').on('show.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown();
		$("#TopLinksInner .dropdown-menu a").addClass("animated");
		window.scrollBy(0, 1);
	});
	$('.toplinks').on('hide.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp();
	});
	if ($('#HomepageMarker').length > 0) {
		var testimonialAuthors = $(".RotatorTestimonialContent a[itemprop='author']");
		if (testimonialAuthors.length > 0) {
			$(".RotatorTestimonialContent a[itemprop='author']").each(function () {
				$(this)[0].nextSibling.nodeValue = '';
			});
		}
		$('.header__logo').addClass("fadeInUp2");
	} else {
		$('.header__logo').css('visibility', 'visible');
	}
	function fixHeights() {
		if (window.matchMedia('(min-width:768px)').matches) {
		}
	}
	setTimeout(fixHeights, 300);
	$(window).on('resize',
		function () {
			setTimeout(fixHeights, 300);
		});
});
